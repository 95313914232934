// sp/pc 判定
var pcMQL = "(min-width: 768px)";
var PC = false;
var SP = true;
if (window.matchMedia(pcMQL).matches) {
	PC = true;
	SP = false;
}

const telBtn = document.querySelector('#is_telBtn');

export function restaurant() {
  if(telBtn != null && PC) {
    telBtn.addEventListener('click', (event) => {
      event.preventDefault();
      telBtn.classList.toggle('is_open');
    })
  }
}