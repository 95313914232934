const body = $('body')
let currentScrollY = 0;

export function fixContetns() {
  currentScrollY = window.scrollY;
  body.css({
    'position' : 'fixed',
    'width' : '100%',
    'top' : -1 * currentScrollY
  });
}
export function unfixContents() {
  body.css({
    'position' : 'static',
    'width' : 'auto',
  });
  window.scrollTo( 0, currentScrollY );
}



// const contents = $('#js-wrap');
// let currentScrollY = 0;

// export function fixContetns() {
//   currentScrollY = window.scrollY;
//   contents.css({
//     'position' : 'fixed',
//     'width' : '100%',
//     'top' : -1 * currentScrollY
//   });
// }
// export function unfixContents() {
//   contents.css({
//     'position' : 'static',
//     'width' : 'auto'
//   });
//   $('html').scrollTop = currentScrollY;
// }
