const imgPath = "/ueno/assets/img/"

export function slideA() {
  $('.js-slideA').slick({
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: `<div class="c-slideA_arrow prev"><div class="c-slideA_arrow_box"><img src="${imgPath}common/icon-arrow-w.png" alt=""></div></div>`,
    nextArrow: `<div class="c-slideA_arrow next"><div class="c-slideA_arrow_box"><img src="${imgPath}common/icon-arrow-w_re.png" alt=""></div></div>`,
    responsive: [{
      breakpoint: 1024,
      settings: {
        arrows: true,
      }
    }]
  });
}

export function slideA2() { // Aの「autoplay」無し版
  $('.js-slideA2').slick({
    dots: true,
    autoplay: false,
    prevArrow: `<div class="c-slideA_arrow prev"><div class="c-slideA_arrow_box"><img src="${imgPath}common/icon-arrow-w.png" alt=""></div></div>`,
    nextArrow: `<div class="c-slideA_arrow next"><div class="c-slideA_arrow_box"><img src="${imgPath}common/icon-arrow-w_re.png" alt=""></div></div>`,
    responsive: [{
      breakpoint: 1024,
      settings: {
        arrows: true,
      }
    }]
  });
}

export function slideB() {
  $('.js-slideB').slick({
    slidesToShow: 3,
    prevArrow: `<div class="c-slideB_arrow prev"><img src="${imgPath}common/icon-arrow.svg" alt=""></div>`,
    nextArrow: `<div class="c-slideB_arrow next"><img src="${imgPath}common/icon-arrow_re.svg" alt=""></div>`,
    responsive: [
      {
        breakpoint: 1024,
        settings: 'unslick'
      }
    ]
  });
}

export function slideC() {
  $('.js-slideC').slick({
    slidesToShow: 1,
    prevArrow: `<div class="c-slideC_arrow prev"><img src="${imgPath}common/icon-arrow.svg" alt=""></div>`,
    nextArrow: `<div class="c-slideC_arrow next"><img src="${imgPath}common/icon-arrow_re.svg" alt=""></div>`,
    responsive: [
      {
        breakpoint: 1024,
        settings: 'unslick'
      }
    ]
  });
}

export function innerSlickIgnition() {
  $('.is_innerSlick').slick({
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: `<div class="c-modalContents_bodyImage_arrow prev"><div class="c-modalContents_bodyImage_arrow_box"><img src="${imgPath}common/icon-arrow-w.png" alt=""></div></div>`,
    nextArrow: `<div class="c-modalContents_bodyImage_arrow next"><div class="c-modalContents_bodyImage_arrow_box"><img src="${imgPath}common/icon-arrow-w.png" alt=""></div></div>`,
    responsive: [{
      breakpoint: 1024,
      settings: {
        arrows: false
      }
    }]
  });
}
