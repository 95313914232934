import { fixContetns, unfixContents } from './modules/fixedContents';
import { simpleModal } from './modules/modal';
import { scrollTop } from './modules/scrollTop';
import { slideA, slideA2, slideB, slideC, innerSlickIgnition } from './modules/slider';
import { restaurant } from './modules/restaurant';
import SmoothScroll from 'smooth-scroll';


document.addEventListener('DOMContentLoaded', function() {

  // Page
  restaurant();
  

  // モーダル単体
  simpleModal();

  // topへ戻る
  scrollTop();

  // slick slider
  slideA();
  slideA2(); // rooms で使用 - Aの「autoplay」無し版
  slideB();
  slideC();


  // header
  const spHeaderToggle = () => {
    $('#js-spHeaderOpen').on('click', function(){
      $('#js-headerSP').css('display', 'block');
      fixContetns();
    });
    $('#js-spHeaderClose').on('click', function(){
      $('#js-headerSP').css('display', 'none');
      unfixContents();
    });
  }
  const spHeaderClose = () => {
    $('.l-header_spMenu_list>ul>li>ul>li>a').on('click', function(){
      $('#js-spHeaderClose').click();
    });
  }

  const spHeaderDropdown = () => {
    $('.js-headerDrop').on('click', function() {
      let $thisCnt = $(this).next();
      let $thisWrap = $(this).parent();
      $thisWrap.toggleClass('active');
      $thisCnt.toggleClass('active');
    });
  }
  const headerCvOpen = () => {
    $('.js-headerCvOpen').on('click', function(){
      $('#js-headerCvCnt').css('display', 'block');
      $('#js-headerCvCnt').addClass('active');
      fixContetns();
    });
  }
  const headerCvClose = () => {
    $('.js-headerCvClose').on('click', function(){
      $('#js-headerCvCnt').fadeOut();
      $('#js-headerCvCnt').removeClass('active');
      unfixContents();
    });
  } 
  const pcHeaderDropdown = () => {
    $('.js-headerHover').hover(
      function() {
        var $thisWidth = $(this).width();
        $(this).find('.js-headerEN').hide();
        $(this).width($thisWidth);
        $(this).find('.js-headerJA').show();
        $(this).children('ul').addClass('active');
      },
      function() {
        $(this).find('.js-headerJA').hide();
        $(this).find('.js-headerEN').show();
        $(this).children('ul').removeClass('active');
      }
    );
  }
  const headerWhich = ()=> {
    const path = location.pathname;
    const isEn = path.split('/')[2] == "en";
    let lowerPath = path.split('/')[3];
    const menuPaths = $('#js-headerMenu > li').children('a');  
    
    if (!isEn) {
      for (let i=0; i<menuPaths.length; i++) {
        if ($(menuPaths[i]).attr('href') === path) {
          $(menuPaths[i]).addClass('active');
        } else if (lowerPath !== "") {
          const parentPath = path.split('/')[2];
          const sitePath = path.split('/')[1];
          const $parentPath = `/${sitePath}/${parentPath}/`;
          if ($(menuPaths[i]).attr('href') === $parentPath) {
            $(menuPaths[i]).addClass('active');
          }
        }
      }
    } else {
      lowerPath = path.split('/')[4];
      for (let i=0; i<menuPaths.length; i++) {
        if (lowerPath !== "") {
          const parentPath = path.split('/')[3];
          const sitePath = path.split('/')[2];
          const $parentPath = `/${sitePath}/en/${parentPath}/`;
          if ($(menuPaths[i]).attr('href') === $parentPath) {
            $(menuPaths[i]).addClass('active');
          }
        }
      }
    }
  }
  spHeaderToggle();
  spHeaderClose();
  spHeaderDropdown();
  pcHeaderDropdown();
  headerWhich();
  headerCvOpen();
  headerCvClose();


  // スムーススクロール
  const smoothScroll = new SmoothScroll('a[href*="#"]', {
    speed: 300,
    updateURL: true,
    popstate: true,
    header: '#js-header',
    offset: 30
  });

  // 他ページからid指定して遷移してきた場合の調整
  // const urlHash = location.hash;
  // const headerHeight = $('.l-header').outerHeight() + 50;
  // if(urlHash) {
  //   setTimeout(function() {
  //     window.scrollBy(0, -headerHeight);
  //   }, 3000);
  // }


  // moreItem
  const moreShow = () => {
    $('.js-moreTri').on('click', function(){
      const moreCnt = $(this).siblings('.js-moreCnt');
      const hideItems = moreCnt.find('.u-anime01');
      for (let i=0; i<hideItems.length; i++) {
        $(hideItems[i]).addClass('active');
      }
      $(this).hide();
    });
  }
  moreShow();

  // タブでコンテンツ切り替え
  const tabSwitch = () => {
    const $triggers = $('#js-tabTri li');
    $triggers.on('click', function() {
      const index = $triggers.index(this);
      $triggers.removeClass('active');
      $(this).addClass('active');
      $('#js-tabCnt > li').removeClass('show').eq(index).addClass('show');
    });
  }
  tabSwitch();


  /**
   * Slickを発火させる関数（親）
   *
   * @param {number} target クリックイベントの対象要素
   */
  const slickIgnition = (target) => {
    let parent = target.currentTarget.closest("ul"); // 親要素取得

    if($(parent).hasClass('is_modalContainsMore')) {
      parent = target.currentTarget.closest('section');
    }

    const parentNum = parent.getAttribute('data-sliderNum'); // 親要素のID取得
    const targetSlider = document.querySelectorAll(`[data-sliderNum = '${parentNum}']`).item(1); // 同一IDを持つNodeを取得
    targetSlider.style.display = 'block'; // 対象のスライダー表示
    // const child = parent.childNodes; // 対象スライダーの子要素取得
    const child = parent.getElementsByTagName('li'); // 対象スライダーの子要素取得
    const parentClass = parent.getAttribute('class'); // 親要素のClass取得
    const slickJudge = parentClass.indexOf('slick-slider'); // Slick内のコンテンツか判定
    // 押下されたスライダーが何番目か判定
    let flag = 0;
    if(slickJudge == -1) {
      flag = 0;
    } else {
      flag = -3; // slickがノードを生成するので調整
    }
    for (let i = 0, len = child.length; i < len; i++) {
      let opts = child.item(i);
      if (opts.nodeType === 1) { // 要素ノードの場合のみ
        flag++;
        if (opts === target.currentTarget) { break }
      }
    }
    $(`[data-sliderNum = '${parentNum}'].is_modalSlick`).slick({
      autoplay: false,
      dots: false,
      swipe: false,
      touchMove: false,
      // fade: true,
      slidesToShow: 1,
      initialSlide: flag - 1, // 対象を初期表示させる
    });
    modalStopPropagation('.c-modalContents_item');
    modalStopPropagation('.slick-arrow');
  }


  /**
   * Slickの初期化関数
   *
   */
  const slickInit = () => {
    $(`#is_modal .slick-slider`).slick('unslick');
    const slickContent = document.querySelectorAll('.is_modalSlick');
    for (let i = 0, len = slickContent.length; i < len; i++) {
      slickContent[i].style.display = 'none';
    }
    // slickContent.forEach((value) => {
    //   value.style.display = 'none';
    // })
  }

  const modal = document.querySelector('#is_modal'); // モーダル

  /**
   * 引数がクリックされたらモーダルを起動
   * 親スライダーと子スライダーを発火
   *
   * @param {string} value モーダルを発火させる要素
   */
  const modalVisible = (value) => {
    value.addEventListener('click', (target) => {
      slickInit();
      modal.classList.add('is_visible');
      slickIgnition(target);
      innerSlickIgnition(); // モーダルないのslick
      fixContetns(); // 背景固定
    })
  }

  /**
   * モーダルを非表示
   * モーダルが閉じられたらslickを初期化
   * @param {string} value モーダルを停止させる要素
   */
  const modalHidden = (value) => {
    const modalHiddenElement = document.querySelectorAll(value);
    for (let i = 0, len = modalHiddenElement.length; i < len; i++) {
      modalHiddenElement[i].addEventListener('click', () => {
        modal.classList.remove('is_visible');
        unfixContents(); // 背景固定解除
        slickInit();
      })
    }
    // modalHiddenElement.forEach((value) => {
    //   value.addEventListener('click', () => {
    //     modal.classList.remove('is_visible');
    //     unfixContents(); // 背景固定解除
    //     slickInit();
    //   })
    // })
  }

  /**
   * イベントの伝搬を停止
   * 
   * @param {string} value モーダル非表示をさせないクラス
   */
  const modalStopPropagation = (value) => {
    const modalStopElement = document.querySelectorAll(value);
    for (let i = 0, len = modalStopElement.length; i < len; i++) {
      modalStopElement[i].addEventListener('click', (event) => {
        event.stopPropagation();
      })
    }
    // modalStopElement.forEach((value) => {
    //   value.addEventListener('click', (event) => {
    //     event.stopPropagation();
    //   })
    // })
  }

  // モーダルを発火させる要素を取得し関数を実行
  const modalIgnition = document.querySelectorAll('.is_modalIgnition');
  for (let i = 0, len = modalIgnition.length; i < len; i++) {
    modalVisible(modalIgnition[i]);
    modalHidden('.is_modalClose');
  }

  // modalIgnition.forEach((value) => {
  //   modalVisible(value);
  //   modalHidden('.is_modalClose');
  // })
})
