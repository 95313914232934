import { fixContetns, unfixContents } from './fixedContents';

const modalUnitBtn = document.querySelectorAll('.c-modalUnit_btn');
const modalUnitContent = document.querySelectorAll('.c-modalUnit_content');
const modalBg = document.querySelector('#js_modalBg');

export function simpleModal() {

  for (let i = 0, len = modalUnitBtn.length; i < len; i++) {
    modalUnitBtn[i].addEventListener('click', () => {
      modalBg.classList.add('-modal');
      modalUnitContent[i].classList.add('-open');
      fixContetns();

      modalBg.addEventListener('click', () => {
        modalBg.classList.remove('-modal');
        modalUnitContent[i].classList.remove('-open');
        unfixContents();
      })

    })

    modalUnitContent[i].addEventListener('click', (event) => {
      event.stopPropagation();
    })

  }

}